import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Personell = () => {
  const { allContentfulHenkilokunta } = useStaticQuery(graphql`
    {
      allContentfulHenkilokunta {
        edges {
          node {
            id
            personName
            personDescription {
              raw
            }
            personImage {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
                resizingBehavior: FILL
              )
              title
            }
          }
        }
      }
    }
  `);
  return (
    <section className="personell">
      <h2>Henkilökunta</h2>
      <ul className="personell-list">
        {allContentfulHenkilokunta.edges.map((person) => {
          const personellImage = getImage(person.node.personImage);
          return (
            <li className="personell-item" key={person.node.id}>
              <div className="personell-image">
                <GatsbyImage
                  image={personellImage}
                  alt={person.node.personImage.title}
                />
              </div>
              <div className="personell-info">
                <h3>{person.node.personName}</h3>
                {person.node.personDescription &&
                  renderRichText(person.node.personDescription)}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Personell;
