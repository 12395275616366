import { Link } from "gatsby";
import React from "react";

const BookingBlock = () => {
  return (
    <section style={{ textAlign: "center" }}>
      <h2>VARAA AIKASI TÄÄLTÄ</h2>
      <Link className="button--outline" to="/ajanvaraus">
        Ajanvaraus
      </Link>
    </section>
  );
};

export default BookingBlock;
